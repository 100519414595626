/* Sidebar */

.sidebar {
  clear: both;

  margin-bottom: 1em;
}
.sidebar::after {
  display: table;
  clear: both;

  content: "";
}
@media (max-width: 64em) {
  .sidebar {
    position: relative;
    z-index: 10;

    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    .author-content {
      h3,
      p {
        color: #fff;
        mix-blend-mode: exclusion;
      }
      text-decoration: none;
    }
  }
  .masthead-hero {
    margin-bottom: -88px;
  }
}
@media (min-width: 64em) {
  .sidebar {
    float: left;

    width: calc(200px - 1em);
  }
  .sidebar.sticky {
    overflow-y: auto;

    height: calc(100vh - 90px - 2em);
  }
}
@media (min-width: 80em) {
  .sidebar {
    width: calc(300px - 1em);
  }
}
.sidebar h2,
.sidebar h3,
.sidebar h4,
.sidebar h5,
.sidebar h6 {
  font-family: "Playfair Display SC", serif;

  margin-bottom: 0;
}
.sidebar p,
.sidebar li {
  font-family: "Dosis", sans-serif;
  line-height: 1.5;
  font-size: 0.9em;
}
.sidebar img {
  width: 100%;
}

.author-avatar {
  display: table-cell;

  width: 40px;
  height: 40px;

  vertical-align: top;
}
@media (min-width: 64em) {
  .author-avatar {
    display: block;

    width: auto;
    height: auto;
  }
}
.author-avatar img {
  max-width: 110px;

  border-radius: 50%;
}
.author-content {
  line-height: 1;

  display: table-cell;

  padding-right: 25px;
  padding-left: 15px;

  vertical-align: top;
}
@media (min-width: 64em) {
  .author-content {
    display: block;

    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}
.author-content a {
  color: inherit;
}
.author-name {
  margin: 0;
}
@media (max-width: 64em) {
  .author-content {
    h3,
    p {
      color: #fff;
      mix-blend-mode: exclusion;
    }
    text-decoration: none;
  }
  .author-content,
  .author-avatar {
    background-color: #fff;
  }
}
@media (min-width: 64em) {
  .author-name {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.sidebar .author-name {
  font-family: "Playfair Display SC", serif;
  font-size: 1.1em;
}
.author-bio {
  margin: 0;
}
@media (min-width: 64em) {
  .author-bio {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.author-urls-wrapper {
  font-family: "Dosis", sans-serif;

  position: relative;
  position: relative;
  z-index: 10;

  display: table-cell;

  cursor: pointer;
  vertical-align: middle;
}
.author-urls-wrapper li:last-child a {
  margin-bottom: 0;
}
@media (min-width: 64em) {
  .author-urls-wrapper {
    display: block;
  }
}
.author-urls-wrapper button {
  margin-bottom: 0;
}
@media (min-width: 64em) {
  .author-urls-wrapper button {
    display: none;
  }
}
.author-urls {
  position: absolute;
  z-index: -1;
  right: 0;

  display: none;

  margin-top: 15px;
  padding: 10px;

  list-style-type: none;
}
.author-urls.is--visible {
  display: block;
}
@media (min-width: 64em) {
  .author-urls {
    position: relative;

    display: block;

    margin: 0;
    padding: 0;

    border: 0;
    background: transparent;
    box-shadow: none;
  }
}
.author-urls:before {
  position: absolute;
  z-index: 0;
  top: -11px;
  left: calc(50% - 10px);

  display: block;

  width: 0;

  content: "";

  border-width: 0 10px 10px;
  border-style: solid;
}
@media (min-width: 64em) {
  .author-urls:before {
    display: none;
  }
}
.author-urls:after {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: calc(50% - 10px);

  display: block;

  width: 0;

  content: "";

  border-width: 0 10px 10px;
  border-style: solid;
  border-color: #fff transparent;
}
@media (min-width: 64em) {
  .author-urls:after {
    display: none;
  }
}
.author-urls li {
  white-space: nowrap;
}
.author-urls a {
  font-size: 1em;

  display: inline-block;
  flex: auto;

  margin-bottom: 5px;
  padding-top: 2px;
  padding-right: 5px;
  padding-bottom: 2px;

  img {
    width: 24px;
    height: 24px;
  }
}
