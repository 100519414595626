/* Page */

.page_content h4 {
  font-size: 1em;

  margin-bottom: 0;
}

@media (min-width: 64em) {
  .page {
    float: right;

    width: calc(100% - 200px);
    padding-right: 0;
  }
}
@media (min-width: 80em) {
  .page {
    width: calc(100% - 300px);
    padding-right: 0;
  }
}
.page {
  margin-bottom: 100px;
}
.page .page_container {
  float: left;
  clear: both;

  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.page .page_container .page_content {
  position: relative;

  float: left;
  clear: both;

  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

