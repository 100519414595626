.masthead-hero {
  position: relative;

  clear: both;

  height: 500px;
  margin-bottom: -32px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.navbar-hero {
  height: 30px;
}

.navbar-hero nav,
.navbar-hero a {
  font-family: "Playfair Display SC", serif;
  font-size: 1em;

  color: #ffb;
  mix-blend-mode: exclusion;
  text-decoration: none;
}

.navbar-hero nav {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.navbar-hero a {
  flex: auto;
  text-align: center;
}

@media (max-width: 64em) {
  .navbar-hero {
    height: 30px;
    nav {
      background-color: #fff;
      mix-blend-mode: inherit;
    }

    a {
      color: #05668d;
      mix-blend-mode: inherit;
    }
  }
}
