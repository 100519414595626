/* Minimal Academic: a two-column Hugo theme for personal sites inspired by Hugo Academic & Minimal Mistakes */
/* Designed by Joshua Hu */
@import url('https://fonts.googleapis.com/css?family=Dosis|Playfair+Display+SC');
* {
  box-sizing: border-box;
}
html {
  font-size: 16px;

  box-sizing: border-box;

  background-color: #fff;

  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}
@media (min-width: 48em) {
  html {
    font-size: 17px;
  }
}
@media (min-width: 64em) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 80em) {
  html {
    font-size: 19px;
  }
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}
img {
  width: auto\9;
  max-width: 100%;
  height: auto;

  vertical-align: middle;

  -ms-interpolation-mode: bicubic;
  border: 0;
}
#map_canvas img, .google-maps img {
  max-width: none;
}
button, input, select, textarea {
  font-size: 100%;

  margin: 0;

  vertical-align: middle;
}
button, input {
  line-height: normal;

  overflow: visible;
}
textarea {
  overflow: auto;

  vertical-align: top;
}
html {
  position: relative;

  min-height: 100%;
}
body {
  font-family: "Dosis", sans-serif;
  line-height: 1.6;

  margin: 0;
  padding: 0;

  color: #000;
}
body.overflow--hidden {
  overflow: hidden;
}
a, a:active, a:visited {
  color: #05668d;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display SC", serif;
  font-weight: bold;
  line-height: 1.25;

  margin: 2em 0 .5em;
}
h1 {
  font-size: 1.563em;

  margin-top: 0;
}
h1 a {
  text-decoration: none;
}
h2 {
  font-size: 1.25em;
}
h3 {
  font-size: 1em;
}
h4 {
  font-size: .75em;
}
h5 {
  font-size: .75em;
}
h6 {
  font-size: .75em;
}
small, .small {
  font-size: .75em;
}
p {
  margin-bottom: 1.3em;
}
u a, ins a {
  color: inherit;
}
del a {
  color: inherit;
}
p, pre, blockquote, ul, ol, dl, figure, table, fieldset {
  orphans: 3;
  widows: 3;
}
blockquote {
  font-style: italic;

  margin: 2em 1em 2em 0;
  padding-right: 1em;
  padding-left: 1em;

  border-left: .25em solid #7a8288;
}
blockquote cite {
  font-style: italic;
}
blockquote cite:before {
  padding-right: 5px;

  content: "\2014";
}
tt, code, kbd, samp, pre {
  font-family: "Share Tech Mono", "Roboto Mono", monospace;
}
pre {
  overflow-x: auto;
}
p > code, a > code, li > code, figcaption > code, td > code {
  font-size: .75em;

  padding-top: .1rem;
  padding-bottom: .1rem;

  border-radius: 4px;
  background: #fafafa;
}
p > code:before, p > code:after, a > code:before, a > code:after, li > code:before, li > code:after, figcaption > code:before, figcaption > code:after, td > code:before, td > code:after {
  content: "\00a0";
  letter-spacing: -.2em;
}
figure {
  display: -webkit-box;
  display: flex;

  margin: 2em 0;

  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
}
figure img, figure iframe, figure .fluid-width-video-wrapper {
  margin-bottom: 1em;
}
figure > a {
  display: block;
}
@media (min-width: 37.5em) {
  figure.half > a, figure.half > img {
    width: calc(50% - .5em);
  }
}
figure.half figcaption {
  width: 100%;
}
@media (min-width: 37.5em) {
  figure.third > a, figure.third > img {
    width: calc(33.3333% - .5em);
  }
}
figure.third figcaption {
  width: 100%;
}
figcaption {
  font-family: "Playfair Display SC", serif;
  font-size: .75em;

  margin-bottom: .5em;

  color: #7a8288;
}
svg:not(:root) {
  overflow: hidden;
}
nav ul {
  margin: 0;
  padding: 0;
}
nav li {
  list-style: none;
}
nav ul li, nav ol li {
  margin-bottom: 0;
}
nav li ul, nav li ol {
  margin-top: 0;
}

#main {
  clear: both;

  max-width: 100%;
  margin-right: auto;
  margin-bottom: 3em;
  margin-left: auto;
  padding-right: 1em;
  padding-left: 1em;
}
#main::after {
  display: table;
  clear: both;

  content: "";
}
@media (min-width: 80em) {
  #main {
    max-width: 1280px;
  }
}

@media (min-width: 64em) {
  .sticky {
    position: -webkit-sticky;
    position:         sticky;
    top: 2em;

    clear: both;
  }
  .sticky::after {
    display: table;
    clear: both;

    content: "";
  }
  .sticky > * {
    display: block;
  }
}

/* Recent posts (homepage) */

.recent-post-container {
  margin-top: 40px;
}

.recent-post {
  margin-bottom: 40px;
}

