/* Sharing */

.share-box {
  float: left;

  margin-top: 40px;
}

ul.share {
  margin-top: 0;
  padding-left: 0;
}

ul.share li {
  display: inline-flex;

  margin-right: 5px;
}

ul.share li .fa {
  line-height: 30px;

  display: block;

  width: 30px;
  height: 30px;

  text-align: center;

  color: #fff;
}

ul.share li a {
  display: block;

  margin: 0;

  text-decoration: none !important;

  border-radius: 50%;
  background-color: #7a8288;
}

