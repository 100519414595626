@import "_mixins.scss";

@font-face {
    font-family: "Playfair Display SC";
    src:
         local("Playfair Display SC"),
         url("fonts/Playfair_Display_SC/PlayfairDisplaySC-Regular.ttf");
}

@font-face {
    font-family: "Playfair Display SC";
    src:
         local("Playfair Display SC Bold"),
         url("fonts/Playfair_Display_SC/PlayfairDisplaySC-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Playfair Display SC";
    src:
         local("Playfair Display SC Black"),
         url("fonts/Playfair_Display_SC/PlayfairDisplaySC-Black.ttf");
    font-weight: bolder;
}

@font-face {
    font-family: "Dosis";
    src:
         local("Dosis Regular"),
         url("fonts/Dosis/OpenSans-Regular.ttf");
}

// @font-face {
//     font-family: "Dosis";
//     src:
//          local("Dosis BoldItalic"),
//          url("fonts/Dosis/OpenSans-BoldItalic.ttf");
//     font-style: italic;
//     font-weight: bold;
// }

// @font-face {
//     font-family: "Dosis";
//     src:
//          local("Dosis Italic"),
//          url("fonts/Dosis/OpenSans-Italic.ttf");
//     font-style: italic;
// }

// @font-face {
//     font-family: "Dosis";
//     src:
//          local("Dosis Bold"),
//          url("fonts/Dosis/OpenSans-Bold.ttf");
//     font-weight: bold;
// }

@font-face {
    font-family: "Dosis";
    src:
         local("Dosis Light"),
         url("fonts/Dosis/OpenSans-Light.ttf");
    font-weight: lighter;
}

@font-face {
    font-family: "Hack";
    src:
         local("Hack"),
         url("fonts/Hack/Hack-Regular.ttf");
}

@font-face {
    font-family: "Hack";
    src:
         local("Hack"),
         url("fonts/Hack/Hack-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Hack";
    src:
         local("Hack"),
         url("fonts/Hack/Hack-BoldItalic.ttf");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Hack";
    src:
         local("Hack"),
         url("fonts/Hack/Hack-Italic.ttf");
    font-style: italic;
}

@import "_index.scss";
@import "_masthead.scss";
@import "_sidebar.scss";
@import "_list.scss";
@import "_page.scss";
@import "_post.scss";
@import "_share.scss";
@import "_comments.scss";
@import "_footer.scss"
