/* Footer */

.page_footer {
  position: absolute;
  bottom: 0;

  float: left;
  clear: both;
  clear: both;

  width: 100%;
  height: auto;
  margin-top: 3em;
  margin-right: 0;
  margin-left: 0;

  text-align: center;

  color: #7a8288;
}
.page_footer::after {
  display: table;
  clear: both;

  content: "";
}
.page_footer footer {
  clear: both;

  max-width: 100%;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1em 2em;
}
.page_footer footer::after {
  display: table;
  clear: both;

  content: "";
}
@media (min-width: 80em) {
  .page_footer footer {
    max-width: 1280px;
  }
}
.page_footer a {
  color: inherit;
}
.page_footer .fas, .page_footer .fab, .page_footer .far, .page_footer .fal {
  color: #7a8288;
}

:-webkit-full-screen-ancestor .masthead, :-webkit-full-screen-ancestor .page_footer {
  position: static;
}

