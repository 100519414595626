/* A post */

.post-container {
  margin-top: 40px;
}

.post-container h1 {
  color: #05668d;
}

.post-header {
  position: relative;

  clear: both;
}

.post-banner {
  width: 100%;
  height: auto;
}

.post-header-caption {
  font-size: .7em;

  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 0;

  margin: 0 auto;
  padding: 2px 5px;

  text-align: right;

  opacity: .65;
  color: #fff;
  border-radius: 5px 0 0 0;
  background: #000;
}
@media (min-width: 64em) {
  .post-header-caption {
    padding: 5px 10px;
  }
}

.post-header-caption a {
  color: #fff;
}

.post-metadata {
  font-size: .9em;

  overflow: hidden;

  margin-bottom: 1em;

  color: #7a8288;
}

.post-metadata a {
  color: #7a8288;
}

.post-style img, .post-style video {
  margin-top: 60px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  padding: 0;
}

.post-style td img, .post-style td video {
  margin-top: 0;
  margin-bottom: 0;
}

.post-style figure {
  margin-top: 60px;
  margin-bottom: 60px;
}

.post-style figure img {
  margin-top: 0;
  margin-bottom: 0;
}

